import React from "react";
import { ConnectButtonComponent } from "./Components/connectButton";
import { NavLink } from "react-router-dom";
import HeaderLogo from "../../Assets/header4.png";
import Website2 from "../../Assets/website2.png";
import "./index.css";


function Header() {
  return (
    <div className="header">
      <div className="headerWrapper">
        <a
          href="https://quarktoken.io/"
          rel="noreferrer"
          className="headerQuarkWbsite"
        >
          <img src={Website2} alt="logo" />
        </a>
        <NavLink to="/" className="headerLogo">
          <img src={HeaderLogo} alt="logo" className="headerTextLogo" />
        </NavLink>
        <ConnectButtonComponent />
      </div>
    </div>
  );
}

export default Header;
