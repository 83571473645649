import { ConnectButton } from '@rainbow-me/rainbowkit';
import "./index.css"
export const ConnectButtonComponent = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button onClick={openConnectModal} type="button" className='connectWallet'>
                    Connect Wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button" className='wrongNetwork'>
                    Wrong Network
                  </button>
                );
              }
              return (
                <div className='walletAddressWrapper'>
                  
                  <button onClick={openAccountModal} type="button" className='walletAddress'>
                    {account.displayName}
                  </button>
                  <button
                    onClick={openChainModal}
                    type="button"
                    className='walletNetwork'
                  >
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl ?? ""}
                            className='walletNetworkIcon'
                          />
                
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};