import React, { useState, useEffect } from "react";
import "../../TokenLock/index.css";
import { NavLink } from "react-router-dom";
import { lockAddress } from "../../../multichain/contractAddresses";
import { rpcUrl } from "../../../multichain/rpcUrl";
import { ethers } from "ethers";
import { useAccount, useNetwork } from "wagmi";
import { useLocation } from "react-router-dom";
import { fetchLockRecord } from "../../../hook/fetchLockRecord";
import { link } from "../../../multichain/explorerLinks";
import moment from "moment";
function LiquidityLockInfo({ cId }) {
  const location = useLocation();
  const [loadMoreData, setLoadMoreData] = useState(10);
  const tokenAddress = location.pathname.split("/")[2];
  const { chain } = useNetwork();
  const chainId = chain?.id;
  const chainRpcUrl = chain?.rpcUrls?.default;
  const contractAddress = lockAddress[chainId ? chainId : cId];
  const provider = new ethers.providers.StaticJsonRpcProvider(
    rpcUrl[cId] 
  );
  const fetchRecord = async () => {
    try {
      const data = await fetchLockRecord(
        provider,
        contractAddress,
        tokenAddress,
        true
      );
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState();
  useEffect(() => {
    setLoading(true);
    fetchRecord().then((res) => {
      setRecord(res);
      setLoading(false);
    });
  }, [chainRpcUrl]);
  const handleGoLinks = (address) => {
    window.open(link[chainId ? chainId : cId] + address, "_blank");
  };
  return (
    <div className="tokenLockInfo">
      <div className="lockInfo">
        <div className="lockInfoTitle">Lock Info</div>
        {loading ? (
          <div
            style={{
              margin: "1rem 0rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap:"4px"
            }}
          >
            Loading <i className="fas fa-spinner fa-spin"></i>
          </div>
        ) : (
          record && (
            <div className="lockInfoContent">
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Token Address</div>
                <a
                  href="#"
                  target="_blank"
                  className="lockInfoContentLink"
                  onClick={() => handleGoLinks(tokenAddress)}
                >
                  {tokenAddress}
                </a>
              </div>
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Token Name</div>
                <div className="lockInfoContentItemText">
                  {record?.tokenData?.name}
                </div>
              </div>
              <div className="lockInfoContentItem">
                <div className="lockInfoContentItemTitle">Token Symbol</div>
                <div className="lockInfoContentItemText">
                  {record?.tokenData?.symbol}
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className="lockInfo">
        <div className="lockInfoTitle">Lock Records</div>
        <div className="lockTable">
          {loading ? (
            <div
              style={{
                margin: "1rem 0rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap:"4px"
              }}
            >
              Loading <i className="fas fa-spinner fa-spin"></i>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Wallet</th>
                  <th>Amount</th>
                  <th>Cycle(d)</th>
                  <th>Cycle Release(%)</th>
                  <th>TGE(%)</th>
                  <th>Unlock Time(Local)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {record && record?.lockRecord?.length > 0 ? (
                  record?.lockRecord?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a
                            href="#"
                            target="_blank"
                            className="lockView"
                            onClick={() => handleGoLinks(item.owner)}
                          >
                            {item.owner.slice(0, 5) +
                              "..." +
                              item.owner.slice(-4)}
                          </a>
                        </td>
                        <td>
                          {item.amount / 10 ** record?.tokenData?.decimals +
                            " "}
                          {record?.tokenData?.symbol}
                        </td>
                        <td>{item.cycle}</td>
                        <td>{item.cycleBps}%</td>
                        <td>{item.tgeBps}%</td>
                        <td>
                          {moment(item.unlockDate * 1000).format(
                            "YYYY-MM-DD hh:mm a"
                          )}
                        </td>
                        <td>
                          <NavLink
                            to={
                              "/liquidity-lock/" + tokenAddress + "/view/" + item.id
                            }
                            className="lockView"
                          >
                            View
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    No Records
                  </div>
                )}
              </tbody>
            </table>
          )}
        </div>
        {/* 
        {loadMoreData < 19 && (
          <div className="lockLoadMore">
            <button onClick={() => setLoadMoreData(loadMoreData + 10)}>
              Load More
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default LiquidityLockInfo;
