import React from 'react'
import "./index.css"
import {FaTelegramPlane,FaTwitter} from 'react-icons/fa'
import DexTools from "../../Assets/dextools_logo.png"
function Footer() {
  return (
    <div className='footer'>
      <div className='footerWrapper'>
        <div className='footerLeft'>© 2023 All rights reserved.</div>
        <div className='footerRight'>
          <a href="https://t.me/QuarkEntryPortal" target="_blank" className='footerLink'><FaTelegramPlane className='footerIcon'/></a>
          <a href="https://twitter.com/QuarkToken" target="_blank" className='footerLink'><FaTwitter className='footerIcon'/></a>
          <a href="https://www.dextools.io/app/en/alvey/pair-explorer/0xfac4066e6025a125bd7804335a7dd30c3406ddf9" target="_blank" className='footerLink'>
            <img src={DexTools}
              alt="dextools"
              className='footerLinkImg'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer