import React from 'react'
import "./index.css"
import { NavLink } from 'react-router-dom'

function HomePage() {
  return (
    <div className='homePage'>
      <NavLink to="/create-lock" className='homeCreateLock'>
        <span>Create Lock</span>
      </NavLink>

      <div className='homeLockView'>
        <NavLink to='/token-lock'className='homeTokenLock'>
          <span>Token Locks</span>
        </NavLink>
        
        <NavLink to='/liquidity-lock' className='homeLpLock'>
          <span>Liquidity Locks</span>
        </NavLink>
      </div>
    </div>
  )
}

export default HomePage