import React, { useEffect, useState } from "react";
import "./index.css";
import { DatePicker } from "antd";
import moment from "moment";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { useAccount, useSigner, useNetwork, erc20ABI } from "wagmi";
import { lockAbi } from "../../Contract/lock";
import { lockAddress } from "../../multichain/contractAddresses";
import { rpcUrl } from "../../multichain/rpcUrl";
import BigNumber from "bignumber.js";
import { fetchLPTokenData } from "../../hook/fetchLockRecord";
import { pairAbi } from "../../Contract/pair";
import { useNavigate } from "react-router-dom";
function CreateLock({ cId }) {
  const navigate = useNavigate();
  const [tokenData, setTokenData] = useState({
    address: "",
    name: "",
    symbol: "",
    decimals: 0,
    allowance: 0,
    balance: 0,
    fee: 0,
  });
  const [anotherOwner, setAnotherOwner] = useState(false);
  const [useVesting, setUseVesting] = useState(false);
  const [isLp, setIsLp] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [lockDate, setLockDate] = useState();
  const [anotherOwnerAddress, setAnotherOwnerAddress] = useState();
  const [tgeDate, setTgeDate] = useState();
  const [tgePercent, setTgePercent] = useState();
  const [cycle, setCycle] = useState();
  const [cycleRelease, setCycleRelease] = useState();
  const [lockTitle, setLockTitle] = useState();
  const [lockAmount, setLockAmount] = useState();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const chainId = chain?.id;
  const chainRpcUrl = chain?.rpcUrls?.default;
  const contractAddress = lockAddress[chainId ? chainId : cId];
  const provider = new ethers.providers.StaticJsonRpcProvider(
  rpcUrl[cId] 
  );

  const [loading, setLoading] = useState(false);
  const [feeLoading, setFeeLoading] = useState(false);
  const fetchFee = async () => {
    try {
      setFeeLoading(true);
      const contract = new ethers.Contract(contractAddress, lockAbi, provider);
      const tx = await contract.getFee();
      setTokenData({
        ...tokenData,
        fee: tx.toString(),
      });
      setFeeLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchFee();
  }, [chainRpcUrl]);
  const fetchTokenData = async () => {
    try {
      let time = new Date().getTime() / 1000;
      setLoading(true);
      const contract = new ethers.Contract(tokenAddress, erc20ABI, provider);
      let name = contract.name();
      let symbol = contract.symbol();
      let decimals = contract.decimals();
      let balance = contract.balanceOf(address);
      let allowance = contract.allowance(address, contractAddress);
      const tokensData = await Promise.all([
        name,
        symbol,
        balance,
        allowance,
        decimals,
      ]);
      setTokenData({
        ...tokenData,
        address: tokenAddress,
        name: tokensData[0],
        symbol: tokensData[1],
        balance: parseInt(ethers.BigNumber.from(tokensData[2]).toString()),
        allowance: parseInt(ethers.BigNumber.from(tokensData[3]).toString()),
        decimals: parseInt(ethers.BigNumber.from(tokensData[4]).toString()),
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTokenData({
        ...tokenData,
        address: tokenAddress,
        name: "",
        symbol: "",
        balance: 0,
        allowance: 0,
        decimals: 0,
      });
      console.log(error);
    }
  };

  const fetchLPTokenData = async () => {
    try {
      let time = new Date().getTime() / 1000;
      setLoading(true);
      const contract = new ethers.Contract(tokenAddress, pairAbi, provider);
      const token0 = contract.token0();
      const token1 = contract.token1();
      const tokens = await Promise.all([token0, token1]);
      const contract0 = new ethers.Contract(tokens[0], erc20ABI, provider);
      const contract1 = new ethers.Contract(tokens[1], erc20ABI, provider);
      let name0 = contract0.name();
      let symbol0 = contract0.symbol();
      let name1 = contract1.name();
      let symbol1 = contract1.symbol();
      let balance = contract.balanceOf(address);
      let allowance = contract.allowance(address, contractAddress);
      const tokensData = await Promise.all([
        name0,
        symbol0,
        name1,
        symbol1,
        balance,
        allowance,
      ]);
      setTokenData({
        ...tokenData,
        address: tokenAddress,
        name: tokensData[0] + "/" + tokensData[2],
        symbol: tokensData[1] + "/" + tokensData[3],
        balance: parseInt(ethers.BigNumber.from(tokensData[4]).toString()),
        allowance: parseInt(ethers.BigNumber.from(tokensData[5]).toString()),
        decimals: 18,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTokenData({
        ...tokenData,
        address: tokenAddress,
        name: "",
        symbol: "",
        balance: 0,
        allowance: 0,
        decimals: 0,
      });
      console.log(error);
    }
  };
  const isLpToken = async (address) => {
    try {
      const contract = new ethers.Contract(address, pairAbi, provider);
      const token0 = await contract.token0();
      if (token0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    if (tokenAddress.length === 42) {
      let issLp = isLpToken(tokenAddress).then((res) => {
        if (!res) {
          fetchTokenData();
          setIsLp(false);
        } else {
          fetchLPTokenData();
          setIsLp(true);
        }
      });
    } else {
      setTokenData({
        ...tokenData,
        address: tokenAddress,
        name: "",
        symbol: "",
        balance: 0,
        allowance: 0,
        decimals: 0,
      });
    }
  }, [tokenAddress]);

  const [approveLoading, setApproveLoading] = useState(false);

  const approveContract = async () => {
    const currentSigner =
      signer === undefined || signer === null ? provider : signer;
    if (signer === undefined || signer === null) {
      toast.error("Please connect your wallet");
      return;
    }
    const contract = new ethers.Contract(tokenAddress, erc20ABI, currentSigner);
    const updateToast = toast.loading("Transaction in progress...");
    setApproveLoading(true);
    try {
      const tx = await contract.approve(
        contractAddress,
        ethers.constants.MaxUint256
      );
      await tx.wait();
      setApproveLoading(false);
      toast.update(updateToast, {
        render: "Transaction successful",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      fetchTokenData();
    } catch (error) {
      setApproveLoading(false);
      toast.update(updateToast, {
        render: error.reason,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      console.log(error);
    }
  };
  const handleMax = () => {
    setLockAmount(tokenData.balance / 10 ** tokenData.decimals);
  };
  const onChangeTimeForVesting = (date, dateString) => {
    let seconds = new Date(dateString).getTime() / 1000;
    setTgeDate(seconds);
  };
  const onChangeTime = (date, dateString) => {
    let seconds = new Date(dateString).getTime() / 1000;
    setLockDate(seconds);
  };

  const [lockLoading, setLockLoading] = useState(false);
  const handleLock = async () => {
    const currentSigner =
      signer === undefined || signer === null ? provider : signer;
    if (signer === undefined || signer === null) {
      toast.error("Please connect your wallet");
      return;
    }

    const contract = new ethers.Contract(
      contractAddress,
      lockAbi,
      currentSigner
    );
    let tx;
    const updateToast = toast.loading("Transaction in progress...");
    setLockLoading(true);
    try {
      if (!useVesting) {
        tx = await contract.lock(
          anotherOwner ? anotherOwnerAddress : address,
          tokenAddress,
          isLp,
          new BigNumber(lockAmount)
            .multipliedBy(new BigNumber(10 ** tokenData.decimals))
            .toString(10),
          lockDate,
          lockTitle,
          { value: tokenData.fee }
        );
      } else {
        tx = await contract.vestingLock(
          anotherOwner ? anotherOwnerAddress : address,
          tokenAddress,
          isLp,
          new BigNumber(lockAmount)
            .multipliedBy(new BigNumber(10 ** tokenData.decimals))
            .toString(10),
          lockDate,
          tgePercent,
          cycle,
          cycleRelease,
          lockTitle,
          { value: tokenData.fee }
        );
      }
      const receipt = await tx.wait();
      setLockLoading(false);
      toast.update(updateToast, {
        render: "Transaction successful",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      if (isLp) {
        navigate(
          "/liquidity-lock/" +
            tokenAddress +
            "/view/" +
            parseInt(receipt.logs[1].topics[1].slice(-10), 16)
        );
      } else {
        navigate(
          "/token-lock/" +
            tokenAddress +
            "/view/" +
            parseInt(receipt.logs[1].topics[1].slice(-10), 16)
        );
      }
    } catch (error) {
      setLockLoading(false);
      toast.update(updateToast, {
        render: error
          ? error.reason !== undefined
            ? error.reason
            : error.message !== undefined
            ? error.message
            : "Something went wrong"
          : "Something went wrong",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        closeButton: true,
      });
      console.log(error);
    }
  };
  const checkInput = () => {
    if (useVesting) {
      if (
        tokenData.name.length > 0 &&
        lockTitle &&
        lockTitle.length > 0 &&
        lockAmount &&
        parseFloat(lockAmount) > 0 &&
        parseFloat(lockAmount) < tokenData.balance / 10 ** tokenData.decimals &&
        tgeDate &&
        tgeDate > new Date().getTime() / 1000 &&
        tgePercent &&
        parseFloat(tgePercent) > 0 &&
        cycle &&
        parseFloat(cycle) > 0 &&
        cycleRelease &&
        parseFloat(cycleRelease) > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        tokenData.name.length > 0 &&
        lockTitle &&
        lockTitle.length > 0 &&
        lockAmount &&
        parseFloat(lockAmount) > 0 &&
        parseFloat(lockAmount) < tokenData.balance / 10 ** tokenData.decimals &&
        lockDate &&
        lockDate > new Date().getTime() / 1000
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <div className="create">
      <div className="createWrapper">
        <div className="createTitle">
          Create Your Lock{" "}
          <div className="feeTitle">
            Fee: {feeLoading ? "-" : tokenData.fee / 1e18} ALV
          </div>
        </div>
        <div className="createContent">
          <div className="createItem">
            <div className="createItemTitle">
              <span>Token or LP Token address</span>
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </div>
            <div className="createItemInput">
              <input
                type="text"
                placeholder="Enter token or LP token address"
                value={tokenAddress}
                onChange={(e) => {
                  setTokenAddress(e.target.value);
                }}
              />
            </div>
            <div className="createError">
              {loading ? (
                <div>
                  Fetching <i className="fas fa-spinner fa-spin"></i>
                </div>
              ) : (
                tokenAddress.length >= 42 &&
                tokenData.name === "" &&
                "Invalid Token Address!"
              )}
            </div>
          </div>

          {/* token info */}
          {tokenData.name !== "" && (
            <div className="createItemToken">
              <div className="createItemTokenInfo">
                <span>Token Name</span>
                <span>{tokenData.name}</span>
              </div>

              <div className="createItemTokenInfo">
                <span>Token Symbol</span>
                <span>{tokenData.symbol}</span>
              </div>

              <div className="createItemTokenInfo">
                <span>Token Decimals</span>
                <span>{tokenData.decimals}</span>
              </div>
            </div>
          )}
          {/* use another owner */}
          <div className="createItem">
            <div className="createCheckbox">
              <input
                type="checkbox"
                checked={anotherOwner}
                onChange={(e) => {
                  setAnotherOwner(e.target.checked);
                }}
              />
              <span>Use another owner?</span>
            </div>
            {anotherOwner && (
              <>
                <div className="createItemTitle">
                  <span>Owner</span>
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </div>
                <div className="createItemInput">
                  <input
                    type="text"
                    placeholder="Ex: 0x..."
                    value={anotherOwnerAddress}
                    onChange={(e) => {
                      setAnotherOwnerAddress(e.target.value);
                    }}
                  />
                </div>
                {anotherOwner && anotherOwnerAddress?.length !== 42 && (
                  <div className="createError">
                    Owner Address cannot be empty or invalid!
                  </div>
                )}

                <div className="createItemInputInfo">
                  The address you input here will receive the tokens once they
                  are unlocked.
                </div>
              </>
            )}
          </div>

          {/* title */}
          <div className="createItem">
            <div className="createItemTitle">
              <span>Lock Title</span>
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </div>
            <div className="createItemInput">
              <input
                type="text"
                placeholder="Ex: My Lock"
                value={lockTitle}
                onChange={(e) => {
                  setLockTitle(e.target.value);
                }}
              />
            </div>
            {lockTitle && lockTitle.length < 1 && (
              <div className="createError">Lock Title cannot be empty!</div>
            )}
          </div>

          {/* amount */}

          <div className="createItem">
            <div className="createItemTitle">
              <span>Amount</span>
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </div>
            <div className="createItemInputAmount">
              <input
                type="number"
                placeholder="Enter Amount"
                value={lockAmount}
                onChange={(e) => {
                  setLockAmount(e.target.value);
                }}
              />
              <button onClick={() => handleMax()}>Max</button>
            </div>
            {lockAmount && lockAmount.length < 1 ? (
              <div className="createError">Amount cannot be empty!</div>
            ) : parseFloat(lockAmount) >
              parseFloat(tokenData.balance / 10 ** tokenData.decimals) ? (
              <div className="createError">
                Amount cannot be greater than your balance!
              </div>
            ) : (
              parseFloat(lockAmount) < 0 && (
                <div className="createError">Amount cannot be less than 0!</div>
              )
            )}
          </div>

          {/* use vesting */}

          <div className="createItem">
            <div className="createCheckbox">
              <input
                type="checkbox"
                checked={useVesting}
                onChange={(e) => {
                  setUseVesting(e.target.checked);
                }}
              />
              <span>Use vesting?</span>
            </div>
          </div>

          {useVesting && (
            <div className="vestingArea">
              <div className="createItemVesting">
                <div className="createItemTitle">
                  <span>TGE Date (Local Time)</span>
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </div>
                <div className="createItemInput">
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    placeholder="Select Date"
                    onChange={onChangeTimeForVesting}
                    disabledDate={(current) => {
                      return current && current < moment().endOf("minute");
                    }}
                    className="extendDateCreate"
                  />
                </div>
                {tgeDate && tgeDate < new Date().getTime() / 1000 && (
                  <div className="createError">
                    TGE Date cannot be less than current time!
                  </div>
                )}
              </div>
              <div className="createItemVesting">
                <div className="createItemTitle">
                  <span>TGE Percent</span>
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </div>
                <div className="createItemInput">
                  <input
                    type="number"
                    placeholder="Ex: 30"
                    value={tgePercent}
                    onChange={(e) => {
                      setTgePercent(e.target.value);
                    }}
                  />
                </div>
                {tgePercent && parseFloat(tgePercent) <= 0 && (
                  <div className="createError">
                    TGE Percent cannot be less than 0!
                  </div>
                )}
              </div>
              <div className="createItemVesting">
                <div className="createItemTitle">
                  <span>Cycle (Days)</span>
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </div>
                <div className="createItemInput">
                  <input
                    type="number"
                    placeholder="Ex: 30"
                    value={cycle}
                    onChange={(e) => {
                      setCycle(e.target.value);
                    }}
                  />
                </div>
                {cycle && parseFloat(cycle) <= 0 && (
                  <div className="createError">
                    Cycle cannot be less than 0!
                  </div>
                )}
              </div>
              <div className="createItemVesting">
                <div className="createItemTitle">
                  <span>Cycle Release Percent</span>
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </div>
                <div className="createItemInput">
                  <input
                    type="number"
                    placeholder="Ex: 30"
                    value={cycleRelease}
                    onChange={(e) => {
                      setCycleRelease(e.target.value);
                    }}
                  />
                </div>
                {cycleRelease && parseFloat(cycleRelease) <= 0 && (
                  <div className="createError">
                    Cycle Release Percent cannot be less than 0!
                  </div>
                )}
              </div>
            </div>
          )}

          {/* unlock time */}

          {!useVesting && (
            <div className="createItem">
              <div className="createItemTitle">
                <span>Lock Until (Local Time)</span>
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </div>
              <div className="createItemInput">
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  placeholder="Select Date"
                  onChange={onChangeTime}
                  disabledDate={(current) => {
                    return current && current < moment().endOf("minute");
                  }}
                  className="extendDateCreate"
                />
              </div>
              {lockDate && lockDate < new Date().getTime() / 1000 && (
                <div className="createError">
                  Lock Until cannot be less than current time!
                </div>
              )}
            </div>
          )}
          {/* warning */}
          <div className="createItem">
            <div className="createWarning">
              Please exclude QuarkLock's lockup address{" "}
              <span
                onClick={() => {
                  navigator.clipboard.writeText(contractAddress);
                  toast.success("Copied to clipboard");
                }}
              >
                {contractAddress}{" "}
              </span>
              from fees, rewards, max tx amount to start locking tokens. We
              don't support rebase tokens.
            </div>
          </div>
          {/* Lock and Approve Button*/}
          <div className="createItem">
            <div className="createButton">
              {tokenData.allowance / 10 ** tokenData.decimals > 1e20 ? (
                <button
                  disabled={checkInput() || lockLoading}
                  onClick={() => handleLock()}
                  style={{
                    cursor:
                      checkInput() || lockLoading ? "not-allowed" : "pointer",
                    opacity: checkInput() || lockLoading ? 0.5 : 1,
                  }}
                >
                  {lockLoading ? "Loading" : "Lock"}
                  {lockLoading && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              ) : (
                <button
                  disabled={approveLoading}
                  style={{
                    cursor: approveLoading ? "not-allowed" : "pointer",
                    opacity: approveLoading ? 0.5 : 1,
                  }}
                  onClick={() => approveContract()}
                >
                  {approveLoading ? "Loading" : "Approve"}
                  {approveLoading && <i className="fas fa-spinner fa-spin"></i>}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateLock;
