import React from 'react'
import { NavLink } from 'react-router-dom'
import "./index.css"

function NotFound() {
  return (
    <div className='notFound'>

       <div className='notFoundContent'>
          <span>404</span>
          <span>Page Not Found</span>
       </div>
       <NavLink to='/' className='notFoundBack'>Back Home</NavLink>
    </div>
  )
}

export default NotFound